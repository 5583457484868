import { createSlice } from "@reduxjs/toolkit";
import { filter } from "lodash";
import { fetchSoftwareList, fetchSoftwareCategoryList } from "./AsyncActions";
import State from "./State";

const initialState = { ...State };

const Slice = createSlice({
  name: "JobSlice",
  initialState,
  reducers: {
    update: (state, action) => {
      state = { ...state, ...action.payload };
    },
    selectCategory: (state, action) => {
      state.SelectedCategory = action.payload;
      state.Subcategories = filter(state.SoftwareList, {
        category: state.SelectedCategory.toString(),
      });
    },
    selectSubcategory: (state, action) => {
      state.Selected = state.Subcategories[action.payload];
    },
    selectSoftware: (state, action) => {
      state.Selected = action.payload;
    },
    updateDescription: (state, action) => {
      state.Description = action.payload;
    },
    updateCurrentOperatingSystem : (state, action) => {
      state.currentOperatingSystem = action.payload;
    },
    updateProblem: (state, action) => {
      state.Problem = action.payload;
    },
    submitJob: (state, action) => {
      state.Selected = state.Subcategories[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSoftwareList.fulfilled, (state, action) => {
      state.SoftwareList = action.payload;
    });
    builder.addCase(fetchSoftwareCategoryList.fulfilled, (state, action) => {
      state.CategoryList = action.payload;
    });
  },
});

export const {
  selectCategory,
  selectSubcategory,
  updateDescription,
  updateCurrentOperatingSystem,
  updateProblem,
  update,
  submitJob,
  selectSoftware,
} = Slice.actions;
export default Slice.reducer;
