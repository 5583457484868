import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { useDetectClickOutside } from 'react-detect-click-outside'
import * as CustomerApi from '../../../../api/customers.api'
import * as jobApi from '../../../../api/job.api'
import * as JobCycleApi from '../../../../api/jobCycle.api'
import * as TwilioApi from '../../../../api/twilioChat.api'
import * as UserApi from '../../../../api/users.api'
import { JobTags, EST_DATE_OPTIONS } from '../../../../constants/index.js'
import { useJob } from "../../../../context/jobContext"
import { useNotifications } from "../../../../context/notificationContext"
import { useSocket } from "../../../../context/socketContext"
import { isWorkingHours, openNotificationWithIcon ,checkAndHandleTimeCollision} from "../../../../utils"
import { getPrimaryTime } from "../../../../utils/index"
import CheckBox from "../CheckBox"
import TimeDropDown from "../TimeDropDown"
import './Style.css'
import * as JobApi from "../../../../api/job.api";
import { ReactSVG } from "react-svg";
import Globe from "../../../../assets/images/globe.svg";

const EditScheduleForLaterModal = ({ isEditScheduleJob, setIsEditScheduleJob,setTechtype, user, setDisableEditForJobButton, submitButton, setSubmitButton ,jobId,  setDisableApplyForJobButton
}) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [isWithin3HoursOrNot, setIsWithin3HoursOrNot] = useState(false)
    let defaultHour = new Date().getMinutes() >= 53 ? new Date().getHours() + 1 : new Date().getHours();
    let defaultHourValue = `${defaultHour > 12 ? defaultHour - 12 : defaultHour}`;
    let defaultMinutes = new Date().getMinutes();
    let defaultMinutesValue = defaultMinutes >= 53 || defaultMinutes < 8 ? "00" : defaultMinutes >= 8 && defaultMinutes < 23 ? "15" : defaultMinutes >= 23 && defaultMinutes < 38 ? "30" : "45";
    let defaultDurationType = defaultHour < 12 ? "AM" : "PM";

    const [editscheduleJobTime, setEditScheduleJobTime] = useState({
        date: new Date(),
        hours: defaultHourValue,
        minutes: defaultMinutesValue,
        durationType: defaultDurationType
    })

    // useEffect(() => {
    //     console.log("isWithin3HoursOrNot : : : : :", isWithin3HoursOrNot)
    // }, [isWithin3HoursOrNot])


    // const [value, setValue] = useState(job?.primarySchedule? new Date(job?.primarySchedule) : new Date());
    const [value, setValue] = useState(editscheduleJobTime.date);
    const [compareValue, setCompareValue] = useState();
    const [calendarValue, setCalendarValue] = useState();
    const [showCalendar, setShowCalendar] = useState(false)
    const { socket } = useSocket();
    const { job,fetchJob } = useJob();
    // const [primaryTime, setPrimaryTime] = useState();
    const [secondryTime, setSecondaryTime] = useState();
    const [showModal, setShowModal] = useState(false)
    const [editDisableButton, setEditDisableButton] = useState(false)
    const { createNotification, fetchNotifications } = useNotifications();
    const [counter, setCounter] = useState(0)
    const [ESTScheduleJobTime, setESTScheduleJobTime] = useState(new Date())

    const onOutsideClick = () => {
        if (counter % 2 !== 0) setCounter(0)
        if (showCalendar && counter % 2 === 0) setShowCalendar(false)
    }

    const handelCalender = (e) => {
        setValue(e)
        setEditScheduleJobTime((prevState => ({ ...prevState, date: e })))
    }
    const handleCancel = (e) => {
        setIsEditScheduleJob(false)
        setSubmitButton(false)
    }

    const deducMoneyFromCustomer = async () => {
        try {
            // This will refund the hold money from customer account

            let ownerStripeId = '';
            const ownerId = job?.customer?.user?.ownerId;

            if (ownerId) {
                const ownerStripeRes = await UserApi.getUserById(ownerId);

                if (ownerStripeRes?.customer?.stripe_id) {
                    ownerStripeId = ownerStripeRes?.customer?.stripe_id;
                }
            }

            const stripeId = ownerId ? ownerStripeId : job?.customer?.stripe_id;

            const obj = {
                payment_hold_id: "not-applicable-here",
                isDeduct: false,
                jobId: job?.id,
                stripe_id: stripeId
            };

            const obj2 = {
                jobId: job?.id,
                stripe_id: stripeId,
                isScheduleCancel: true,
            };

            let scheduleCancelResponse;

            await JobCycleApi.create(JobTags.CUSTOMER_CANCEL_ACCEPTED_SCHEDULE_JOB, job?.id);
            scheduleCancelResponse = await CustomerApi.deductCancelJobPayment(obj2);
            // console.log('scheduleCancelResponse :: :: 1', scheduleCancelResponse)
            // if (job && job?.customer_holded_payments && job?.customer_holded_payments.length > 0) {
            //     await CustomerApi.deductOrRefundHoldMoney(obj);
            //     console.log('scheduleCancelResponse :: :: 2', scheduleCancelResponse)
            // }

            if (scheduleCancelResponse?.status === 'Successful') {
                await JobCycleApi.create(JobTags.PAYMENT_DEDUCTED_FROM_CUSTOMER, job?.id);
                // console.log('scheduleCancelResponse :: :: 3', scheduleCancelResponse)
                openNotificationWithIcon('success', 'Success', 'Job has been updated.');
                return true;
            } else {
                // console.log('scheduleCancelResponse :: :: 4', scheduleCancelResponse)
                openNotificationWithIcon('error', 'Error', scheduleCancelResponse.message);
                setIsDisabled(false)
                return false;
            }
            // console.log('scheduleCancelResponse :: :: 4',scheduleCancelResponse)

        } catch (error) {
            setIsDisabled(false)
            // Handle the error appropriately, you can log it, show an error message, or take other actions.
            console.error('Error in deducMoneyFromCustomer:', error);
            // Optionally, rethrow the error if you want it to be caught by an outer try-catch block.
            throw error;
        }
    };

    const handleOk = async (e) => {
        e.preventDefault()

        let scheduleTimeNew = getPrimaryTime(editscheduleJobTime);
        const latestpendingJobs = await JobApi.latestpendingJobs({ "customer": user.customer.id });
      console.log('latest pending pob ::', latestpendingJobs)

      const scheduleJobsArray = latestpendingJobs?.statusJobs?.find(entry => entry.status === 'Scheduled')?.jobs || [];
     // const existingJobs = scheduleJobsArray;
  
      const existingJobs = scheduleJobsArray;
    
      const newScheduledTime = new Date(scheduleTimeNew);
      // Check if there is an existing scheduled job that collides with the new time
     const hasCollisions= checkAndHandleTimeCollision(existingJobs,newScheduledTime)
        const hours = scheduleTimeNew.getHours();
        const minutes = scheduleTimeNew.getMinutes();
        const durationType = hours < 12 ? "AM" : "PM";

        let formattedData = {
            date: scheduleTimeNew.toString(),
            hours: hours,
            minutes: minutes,
            durationType: durationType
        };

        // const isWithinWorkingHours = isWorkingHours(formattedData);
        // if (!isWithinWorkingHours) {
        //     openNotificationWithIcon('error', 'Error', "Our techs are mostly available between 9am-9pm EST Mon-Fri. Please schedule a good time during these business hours.");
        //     return;
        // }
        setSubmitButton(true)
        // console.log(scheduleTimeNew, job.primarySchedule, "My console to check schedule job time 1", new Date(scheduleTimeNew))
        const hourDifferenceFromNow = scheduleTimeNew - new Date().getTime()
        const timeDifferenceFromPreviousTime = new Date(scheduleTimeNew).getTime() - new Date(job.primarySchedule).getTime()
        // console.log(new Date(scheduleTimeNew).getTime(), "My console to check schedule job time 1", new Date(job.primarySchedule).getTime())
        // console.log(timeDifferenceFromPreviousTime, "My console to check schedule job time 1")
        if (hourDifferenceFromNow < 1800000) {
            openNotificationWithIcon("error", "Error", "Please schedule a job at least 30 minutes from now.")
            setSubmitButton(false)
        } else if (timeDifferenceFromPreviousTime === 0) {
            openNotificationWithIcon("error", "Error", "Your job is already scheduled with this time! Please select a different date and time.")
            setSubmitButton(false)
        } else if (hasCollisions) {
            openNotificationWithIcon('error', 'Error', "One of your job is already scheduled with same date and time. Please pick any other time to schedule new job");
            setSubmitButton(false)
          } else {
            if (isWithin3HoursOrNot && job?.schedule_accepted) {
                let paymentResponse = await deducMoneyFromCustomer()
                if (paymentResponse) {
                    await updateScheduleJob(job)
                    await TwilioApi.updateTwilioConversation(job?.twilio_chat_service?.sid)
                    socket.emit("refresh-ScheduleTime", job)
                    setIsEditScheduleJob(false)
                }else{
                    setSubmitButton(false)
                }
            } else {
                await updateScheduleJob(job)
                await TwilioApi.updateTwilioConversation(job?.twilio_chat_service?.sid)
                socket.emit("refresh-ScheduleTime", job)
                setIsEditScheduleJob(false)
            }
        }
    }

    const ref = useDetectClickOutside({ onTriggered: onOutsideClick });

    let todayDate = new Date();
    let todaydd = `${todayDate.getFullYear()}${todayDate.getMonth()}${todayDate.getDate()}`;

    useEffect(()=>{
        const hours24 = editscheduleJobTime.durationType === "AM" ? editscheduleJobTime.hours % 12 : (editscheduleJobTime.hours % 12) + 12;
        const minutes = editscheduleJobTime.minutes
        let selectedScheduleDateAndTime = editscheduleJobTime.date
        selectedScheduleDateAndTime = editscheduleJobTime.date.setHours(hours24)
        selectedScheduleDateAndTime = new Date(editscheduleJobTime.date).setMinutes(minutes)
        setESTScheduleJobTime(new Date(selectedScheduleDateAndTime))
    },[editscheduleJobTime])

    useEffect(() => {
        let todayCalenderValue = `${todayDate.getFullYear()}${value.getMonth()}${value.getDate()}`;
        setCompareValue(todayCalenderValue)
        setCalendarValue(`${value.toString().split(" ")[1]} ${value.toString().split(" ")[2]}`)
    }, [value])
    useEffect(() => {
        socket.on("refreshScheduleTime", async(data) => {
        if (data.id === jobId) {
          setTechtype("apply");
          setDisableApplyForJobButton(false);
          await fetchJob(data.id);
        }
      });
       
  
    }, [])
    let minArray = ["00", "15", "30", "45"];

    let hourArray = [];

    for (let i = 1; i <= 12; i++) {

        hourArray.push(String(i));
    }

    let scheduleDetails = {
        'primaryTimeAvailable': true,
        'primaryTimeExpiredAt': null,
        'secondaryTimeAvailable': true,
        'secondaryTimeExpiredAt': null,
        'scheduleExpired': false,
        'scheduleExpiredAt': null
    }

    /**
     * update Schedule job data as per requirement. send alert as notification, sms and email
     * @params : userId: user_id, 
     * 	userEmail: email id of customer/technician, 
     * 	jobStats: job data, 
     * 	title: message for notification
     * @returns : null
     * @author : Ridhima Dhir
     */
    const updateScheduleJob = async (job) => {
        const scheduleJobData = {}
        let scheduleDetails = {
            'primaryTimeAvailable': true,
            'primaryTimeExpiredAt': null,
            'secondaryTimeAvailable': false,
            'secondaryTimeExpiredAt': null,
            'scheduleExpired': false,
            'scheduleExpiredAt': null
        }
        const primaryTime = getPrimaryTime(editscheduleJobTime);
        scheduleJobData.primarySchedule = primaryTime;
        scheduleJobData.scheduleDetails = scheduleDetails
        scheduleJobData.scheduleDetails.scheduleExpiredAt = new Date(primaryTime - 300000)
        // scheduleJobData.secondrySchedule = secondryTime;
        // scheduleJobData.scheduleDetails = {... scheduleDetails,
        //     scheduleExpiredAt:new Date(secondryTime.getTime() - 20 * 60000)
        // };
        // console.log("job.technician ::: main", job.technician)
        let oldTech = {}
        let techNumber = ''
        if (job.technician && job.technician.profile && job.technician.profile.alertPreference && job.technician.profile.alertPreference.settings && job.technician.profile.alertPreference.settings.Job['Text']['toggle']) {
            techNumber = job.technician['profile']['alertPreference']['settings']['Job']['Text']['value']
        }
        if (user.userType === 'customer' && job.technician) {
            scheduleJobData.technician = ''
            scheduleJobData.schedule_accepted = false
            scheduleJobData.status = 'Scheduled'
            scheduleJobData.tech_message_dashbord = false

            oldTech = {
                id: job.id,
                techId: job.technician['user'].id,
                firstName: job.technician['user'].firstName,
                email: job.technician['user'].email,
                timezone: job.technician['user']['timezone'],
                number: techNumber,
                by: "Customer " + user.firstName
            }
            // console.log("oldTech ::: before", oldTech)
        }
        // console.log("jobStats :::", oldTech)


        if (job?.post_again_reference_technician) {
            scheduleJobData.chatRoomId = job.chatRoomId
        } else {
            scheduleJobData.chatRoomId = null
        }

        const jobStats = await jobApi.updateJob(job.id, scheduleJobData);
        let custNumber = ''
        if (job.customer && job.customer.phoneNumber) {
            custNumber = job.customer.phoneNumber
        }
        if (user.userType === 'customer') {
            // console.log("oldTech ::: middle", oldTech)
            let title = "Job has been successfully updated. We are finding a technician for you. We will inform you when we find the technician."
            mixpanel.identify(user.email);
            mixpanel.track('Job meeting time updated by customer', { 'JobId': job.id });
            if (job.technician) {
                // console.log("oldTech ::: after", oldTech)
                title = "Job has been successfully updated by customer and technician removed from job. We are finding a technician for you. We will inform you when we find the technician"
                await socket.emit('updated_schedule_job_accepted_technician_email', oldTech)
                await emitSocketCreateFetchNotification(oldTech.techId, oldTech.email, jobStats, "Meeting time of your accepted job with " + job.customer.user.firstName + " has been changed")
                if (oldTech.number) {
                    await socket.emit('schedule_job_time_change_alert', oldTech)
                }
                mixpanel.track('Technician ' + oldTech.firstName + ' is removed from job.', { 'JobId': job.id });
            }
            await emitSocketCreateFetchNotification(user.id, user.email, jobStats, title)
            if (custNumber) {
                await socket.emit('schedule_job_time_change_alert', { id: job.id, number: custNumber, by: "Customer " + user.firstName })
            }
            // call send-schedule-alerts socket from backend.
            // It will find available techs and send alerts by sms/email/notification
            // emit send-schedule-alerts socket
            socket.emit('search-for-tech', {
                jobId: jobStats.id,
                customerTimezone: user.timezone,
                jobObj: jobStats,
                primaryTime,
                secondryTime,
                phoneNumber: user.customer.phoneNumber,
                customerName: user.firstName,
                customerEmail: user.email,
                technicianId: (job && job?.post_again_reference_technician ? job.post_again_reference_technician : false),
            });
        }
        if (user.userType === 'technician') {
            mixpanel.identify(user.email);
            mixpanel.track('Job meeting time updated by technician', { 'JobId': job.id });
            // NOTE  : This socket is commented by Jagroop under ticket GK-171  : 28-04-2023
            // await socket.emit('schedule_job_updated_by_technician_to_customer_email', jobStats.id)
            let title = "Job Meeting has been updated by technician."
            await emitSocketCreateFetchNotification(job.customer.user.id, job.customer.user.email, jobStats, title)
            if (custNumber) {
                await socket.emit('schedule_job_time_change_alert', { id: job.id, number: custNumber, by: "Technician " + user.firstName })
            }
            if (techNumber) {
                await socket.emit('schedule_job_time_change_alert', { id: job.id, number: techNumber, by: "Technician " + user.firstName })
            }
        }
        // props.fetchJob(job.id);
        socket.emit("edit-job", job.id)
        setShowModal(false)
        setIsEditScheduleJob(false);
        setDisableEditForJobButton(false);
        if (editDisableButton) { setEditDisableButton(false) }

    };

    /**
     * create / fetch notification customer notifications
     * @params : userId: user_id, 
     * 	userEmail: email id of customer/technician, 
     * 	jobStats: job data, 
     * 	title: message for notification
     * @returns : null
     * @author : Ridhima Dhir
     */
    const emitSocketCreateFetchNotification = async (userId, userEmail, jobStats, title) => {
        try {
             console.log("send-schedule-alerts :::::::::::")
            //Notification for customer
            const notificationData = {
                user: userId,
                job: jobStats.id,
                read: false,
                actionable: false,
                title: title,
                type: 'Scheduled Job',
            };
            // console.log("notificationData ::::::::", notificationData)
            await createNotification(notificationData);
            console.log("fetchNotifications() :: called emitSocketCreateFetchNotification function in EditScheduleForLaterModal/index.ts");
            await fetchNotifications({ user: userId });
        } catch (err) {
            mixpanel.identify(userEmail);
            mixpanel.track('There is catch error while create/fetch notification', { jobStats: jobStats, errMessage: err.message });
            // consoleLog('There is catch error while create/fetch notification  :::: '+ err.message)
        }
    }

    const handleTodayClick = () => {
        setShowCalendar(!showCalendar);
        setCounter(counter + 1);
        if (counter % 2 !== 0) setCounter(1)
    }


    const handleClose = () => {
        setIsEditScheduleJob(false)
        setSubmitButton(false)
    }

    useEffect(() => {
        if (user?.userType === 'customer') {
            const givenTime = job?.primarySchedule; // Replace this with the time you want to check
            const result = isWithin3Hours(givenTime);

            if (result) {
                setIsWithin3HoursOrNot(true)
            } else {
                // console.log("The given time is NOT within 3 hours from the current time.");
                setIsWithin3HoursOrNot(false)
            }
        }
    }, [job])

   function isWithin3Hours(givenTime) {
    const currentTime = new Date().getTime();
    const parsedGivenTime = new Date(givenTime).getTime();
    const timeDifference = parsedGivenTime - currentTime;

    return Math.abs(timeDifference) <= 3 * 60 * 60 * 1000 || parsedGivenTime < currentTime;
}


    return (
        <>
            <div>
                <Modal
                    open={isEditScheduleJob}
                    onClose={() => { setIsEditScheduleJob(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="outer-box-modal1">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} className="close-icon-btn" style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="text-div mg-b-10">
                            <div className="end-the-job-div">
                                <span className="close-icon-btn-text font-nova">
                                    {(isWithin3HoursOrNot && job?.schedule_accepted) ? (
                                        (user?.roles[0] !== "owner" && user?.roles[0] !== "admin")?
                                        "A Geek has already accepted your job, so your company may incur a small fee for no-shows or last-minute Reschedule. Are you sure you want to Reschedule the job?"
                                        :
                                        "Please note, we offer free reschedule up to 3 hours before your meeting. However your scheduled job is less than 3 hours away. If you reschedule now, a $24.99 fee will apply"
                                    ) : "Are you sure you want to Reschedule the job?"}
                                </span>

                            </div>

                        </div>
                        <div style={{ width: '95%' }}>
                            <div className='d-flex justify-content-start flex-wrap mb-20'>
                                <div>
                                    <div>
                                        <label className='date-label-div m-l'>Date:</label>
                                    </div>
                                    <div>
                                        <div className={`${!showCalendar === false ? 'today-div-true d-flex justify-content-start align-items-center' : 'today-div d-flex justify-content-start align-items-center'}`} onClick={handleTodayClick}>
                                            {/* {console.log("My console to check", { compareValue, todaydd, calendarValue })} */}
                                            <span className="date-value m-l ">
                                                {compareValue === todaydd ? "Today" : calendarValue}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-10-max-width-600 mb-30-max-width-600">
                                    <div>
                                        <div>
                                            <label className='date-label-div '>Time:</label>
                                        </div>
                                        <div className="time-div d-flex justify-content-center align-items-center">
                                            <TimeDropDown
                                                dropdownValues={hourArray}
                                                name={"hour"}
                                                editscheduleJobTime={editscheduleJobTime}
                                                setEditScheduleJobTime={setEditScheduleJobTime}
                                            />
                                            <span className="colon-dropdown"> :</span>
                                            <TimeDropDown
                                                dropdownValues={minArray}
                                                name={"minutes"}
                                                editscheduleJobTime={editscheduleJobTime}
                                                setEditScheduleJobTime={setEditScheduleJobTime}
                                            />
                                        </div>
                                    </div>
                                    <div className="check-box">
                                        <CheckBox
                                            editscheduleJobTime={editscheduleJobTime}
                                            setEditScheduleJobTime={setEditScheduleJobTime}
                                        />
                                    </div>
                                </div>
                            </div>
                            {user && <div className="d-flex align-items-center gap-8 w-100percent">
                                <ReactSVG src={Globe} />
                                <div className="d-flex flex-column">
                                {/* <span className="localTime text-left" >Geeker Business Hours</span> */}
                                <span className="localTime text-left white-space-break" >{`Eastern Standard Time (EST):`+ new Date(ESTScheduleJobTime).toLocaleTimeString("en-US", EST_DATE_OPTIONS )}</span>
                                </div>
                            </div>}

                            {showCalendar &&
                                <div className="calendar-container border-calander" ref={ref}>
                                    <Calendar
                                        // tileDisabled={({ date }) => [0, 6].includes(date.getDay())}
                                        // tileClassName={({ date, view }) => {
                                        //     if (view === 'month' && ![0, 6].includes(date.getDay())) {
                                        //         return 'allowed-date';
                                        //     }
                                        //     return 'disabled-date';
                                        // }}
                                        onChange={(e) => { handelCalender(e) }}
                                        value={value}
                                        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
                                        minDate={new Date()}
                                    />
                                </div>
                            }
                        </div>

                        <div className="d-flex justify-content-end buttons-container mg-b-10 ">
                            <div className="mg-b-10">
                                <Stack className='margin-right-16px' spacing={2} direction="row">
                                    <Button variant="outlined" className="Outlined-btn-join" onClick={handleCancel}>
                                        <span className="join-btn-css">Close</span>
                                    </Button>
                                </Stack>
                            </div>

                            <div className="mg-b-10">
                                <Stack spacing={2} direction="row">
                                    <Button variant="outlined" className="contained-end-btn"
                                        onClick={submitButton ? () => { } : handleOk}
                                        disabled={submitButton}>
                                        {submitButton ? <CircularProgress className='spin-css' /> :

                                            <span className="join-btn-css color-white" >Reschedule Job</span>
                                        }
                                    </Button>
                                </Stack>
                            </div>
                        </div>

                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default EditScheduleForLaterModal
